import * as SentrySvelte from '@sentry/svelte';
import { BrowserTracing } from '@sentry/tracing';
import { dev } from '$app/environment';

SentrySvelte.init({
	dsn: 'https://bf2959e2616d4ad4b5080a501aed6505@o1344825.ingest.sentry.io/4504475740405760',
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0
});

SentrySvelte.setTag('svelteKit', 'browser');
if (dev) {
	SentrySvelte.setTag('environment', 'development');
}

// This will catch errors in `load` functions from `+page.ts` files
export const handleError = ({ error, event }) => {
	SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } });

	return {
		message: error.message
	};
};
